import { 
    LOADING_LOGIN, 
    SET_ERRORS, 
    SET_USER,
    LOGOUT
} from '../types/auth';
import axios from 'axios';

const loginUser = async (loginData, dispatch) => {
    dispatch({
        type: LOADING_LOGIN
    })

    const { email, password } = loginData

    const errors = {}

    if(email === ""){
        errors.email = "Email address is required"
    }

    if(password === ""){
        errors.password = "Password is required"
    }

    if(!email || !password){
        dispatch({
            type: SET_ERRORS,
            payload: errors
        })
        return
    }

    // authenticate

    try {
        const response = await axios.post(`auth/admin/login`, loginData);

        const data = response.data.data;

        dispatch({
            type: SET_USER,
            payload: data
        })

        localStorage.setItem('token', `Bearer ${data.token}`)

        // history.push("/admin")
        window.location.href="/admin"
    } catch (error) {
        errors.general = error.response.data.message;

        dispatch({
            type: SET_ERRORS,
            payload: errors
        })
    }
}

const logoutUser = async (history, dispatch) => {
    dispatch({
        type: LOGOUT
    })
    localStorage.clear()
    history.push('/login')
}

export {
    loginUser,
    logoutUser,
}