const LOADING_LOGIN = 'LOADING_LOGIN'
const CLEAR_ERRORS = 'CLEAR_ERRORS';
const SET_ERRORS = 'SET_ERRORS';
const SET_USER = 'SET_USER';
const LOGOUT = 'LOGOUT';

export {
    LOADING_LOGIN, 
    CLEAR_ERRORS, 
    SET_ERRORS, 
    SET_USER, 
    LOGOUT
}