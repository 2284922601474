import React from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import MarkDeliveredButton from './MarkDeliveredButton';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { CLOSE_ORDER_DETAILS_DRAWER } from '../../redux/types/app';

import { 
    ListItemTextExtended, 
    ListItemSecondaryActionExtended 
} from 'mui-listitem-extended';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

const DeliveryOrderDetails = ({ orderDetails }) => {
    const drawerOpen = useSelector(state => state.app.drawerOpen);

    const dispatch = useDispatch()
    
    return (
        <Drawer
            anchor="bottom"
            open={drawerOpen}
        >
            <Box
            sx={{ width: 'auto'}}
            role="presentation"
            style={{
                marginTop: "10px", 
                marginLeft: "15px",
                marginBottom: "10px"
            }}
            >
                <DrawerHeader>
                    Order Details
                    <IconButton style={{position: 'absolute', right: 10, backgroundColor: '#cbdef2'}} onClick={() => dispatch({
                        type: CLOSE_ORDER_DETAILS_DRAWER
                    })}>
                        <KeyboardArrowDownIcon />
                    </IconButton>
                </DrawerHeader>
                <Divider/>
                { orderDetails.orderDetails ? (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={11}>
                            <Typography variant="body2"><strong>Customer Name:</strong> {orderDetails.orderDetails.customer_name}</Typography>
                            <Typography variant="body2"><strong>Phone Number:</strong> {orderDetails.orderDetails.customer_phone}</Typography>
                            <Typography variant="body2"><strong>Total:</strong> {orderDetails.orderDetails.total_amount.toLocaleString('en-US', {
                                style: 'currency',
                                'currency':'KES'
                            })}</Typography>
                            <Typography variant="body2"><strong>Shipping Area:</strong> {orderDetails.orderDetails.shipping_area.name}</Typography>
                            <Typography variant="body2"><strong>Delivery Address:</strong> {orderDetails.orderDetails.shipping_address.delivery_address}</Typography>
                        </Grid>
                    </Grid>

                    <Grid style={{marginTop: "5px"}} container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="button">Order Products</Typography>
                            <Divider/>
                            {orderDetails.orderDetails.products.map((product) => {
                                return (
                                <React.Fragment  key={product.id}>
                                    <ListItem>
                                        <ListItemTextExtended primary={product.name} secondary={product.total_amount.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'KES'
                                        })}/>
                                        <ListItemSecondaryActionExtended>
                                            <Typography>{product.quantity}</Typography>
                                        </ListItemSecondaryActionExtended>
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                                );
                            })}
                            {orderDetails.orderDetails.order_status.status === "pending" ? (
                            <>
                                <Divider/><br/>
                                <MarkDeliveredButton orderNumber={orderDetails.orderDetails.order_number} />
                            </>) : null}
                        </Grid>
                    </Grid>
                </>
                ) : 
                <Grid container>
                    <Grid item xs={12}>
                        <Stack spacing={1}>
                            <Skeleton variant="text" />
                            <Divider/>
                            <Skeleton variant="rectangular" width={210} height={118} />
                        </Stack>
                    </Grid>
                </Grid>
                }
            </Box>
        </Drawer>
    )
}

export default DeliveryOrderDetails
