import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';

const NoResults = () => {
    return (
    <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="success">
            <AlertTitle><strong>No Pending Deliveries</strong></AlertTitle>
            If you think this is an error, please contact support.
        </Alert>
    </Stack>
    )
}

export default NoResults