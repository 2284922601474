import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';

import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Components
import Copyright from '../components/Auth/AuthCopyright';
import LoginForm from '../components/Auth/LoginForm';

const theme = createTheme();

const Login = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <LoginForm/>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}

export default Login