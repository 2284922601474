import styled from 'styled-components';

const StyledAside = styled.aside({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '10vh',
    padding: '1.5rem',
    fontSize: 'xl',
    color: 'white',
    backgroundColor: 'red',
  });

const ErrorMessage = ({ message }) => {
  return <StyledAside>{message}</StyledAside>;
}

export default ErrorMessage;