import {
    MARKING_AS_COMPLETE,
    MARKED_AS_COMPLETE,
    SET_ERRORS
} from '../types/delivery';

const INITIAL_STATE = {
    loadingMarkComplete: false,
    markedComplete: false,
    errors: null,
}

export const deliveryReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        case MARKING_AS_COMPLETE:
            return{
                ...state,
                loadingMarkComplete: true,
            }
        case MARKED_AS_COMPLETE:
            return {
                ...state,
                loadingMarkComplete: false,
                markedComplete: true
            }
        case SET_ERRORS:
            return {
                ...state,
                loadingMarkComplete: false,
                markedComplete: false,
                errors: action.payload
            }
        default:
            return state;
    }
}