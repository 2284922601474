import React from 'react'
import { 
    ListItemTextExtended, 
    ListItemSecondaryActionExtended 
} from 'mui-listitem-extended'
import Typography from '@mui/material/Typography';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';

export const OrdersList = ({ deliveries, toggleDrawer }) => {
    return (
        <>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {deliveries.map((order, key) => {
                return (
                <React.Fragment  key={key}>
                    <ListItem onClick={toggleDrawer("bottom", true, order.order_number)}>
                        <ListItemTextExtended primary={order.order_number} secondary={order.shipping_area.name}/>
                        <ListItemSecondaryActionExtended>
                            <Typography>{`${order.total_amount.toLocaleString('en-US', {
                                style: 'currency',
                                'currency':'KES'
                            })}`}</Typography>
                        </ListItemSecondaryActionExtended>
                    </ListItem>
                    <Divider />
                </React.Fragment>
                );
            })}
            </List>
        </>
    )
}
