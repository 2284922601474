import axios from 'axios';

import {
    MARKING_AS_COMPLETE,
    MARKED_AS_COMPLETE,
    SET_ERRORS
} from '../types/delivery';

import {
    CLOSE_ORDER_DETAILS_DRAWER
} from '../types/app';

export const markOrderCompleted = async (dispatch, orderNumber) => {
    dispatch({
        type: MARKING_AS_COMPLETE
    })

    try {
        const rider = JSON.parse(localStorage.getItem('user'))

        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}delivery/${orderNumber}/mark-completed`, null, {
            headers: {
                'Authorization': `Bearer ${rider.token}`
            }
        })

        if (response.data.status === 200) {
            dispatch({
                type: MARKED_AS_COMPLETE
            })

            dispatch({
                type: CLOSE_ORDER_DETAILS_DRAWER
            })
        }
    } catch (error) {
        dispatch({
            type: SET_ERRORS,
            payload: error.message,
        })
    }
}