import './App.css';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
// Pages
import Dashboard from './views/Dashboard';
import Login from './views/Login';
import Deliveries from './views/CompletedDeliveries';
// Redux
import { Provider } from 'react-redux'
import { store } from "./redux/store";

const App = () => {
  return (
    <Provider store={store}>
      <CssBaseline/>
        <BrowserRouter>
            <Switch>
              <Route path="/login" render={(props) => <Login {...props} />} />
              <Route path="/" render={(props) => <Dashboard {...props} />} exact />
              <Route path="/deliveries" render={(props) => <Deliveries {...props} />} />
            </Switch>
        </BrowserRouter>
    </Provider>
  );
}

export default App;
