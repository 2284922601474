import React from 'react';
import Header from '../components/AppBar/AppBar';
import OrdersCount from '../components/OrdersCount/OrdersCount';
import PendingDeliveries from '../components/Deliveries/PendingDeliveries';
import BottomNav from '../components/BottomNav/BottomNav';
import Container from '@mui/material/Container';
import jwt_decode from "jwt-decode";

function Dashboard() {
    const rider = JSON.parse(localStorage.getItem('user'))

    if(!rider){
        window.location.href = "/login"
    }

    let tokenData

    if (rider.token){
        // decode the token
        tokenData = jwt_decode(rider.token)
    }

    if(!rider.token || (Date.now() >= new Date(tokenData.exp).getTime() * 1000)){
        localStorage.clear();
            
        window.location.href = "/auth/login"
    }

    return (
        <div>
            <Header/>
            <Container maxWidth="sm">
                <OrdersCount/>
                <PendingDeliveries/>
                <BottomNav/>
            </Container>
        </div>
    )
}

export default Dashboard
