import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import { Formik } from "formik";
import * as Yup from "yup";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { authenticateRider } from '../../data/auth';

const validationSchema = Yup.object().shape({
    phone_number: Yup.string()
        .required("Required")
        .min(9, "Invalid phone number"),
    password: Yup.string().required("Required")
});

const initialValues = {
    phone_number : "",
    password : "",
};

const LoginForm = () => {

    const history = useHistory();

    const handleSubmit = async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
      ) => {
        try {
            const requestData = {
                phone_number: values.phone_number.toString(),
                password: values.password,
            };

            const response = await authenticateRider(requestData);

            if (response.status !== 200) {
                setStatus({ sent: false });
                setErrors({ submit: "Invalid login credentials" });
                setSubmitting(false);
                return;
            }

            localStorage.setItem("user", JSON.stringify(response.data));

            // authDispatch({
            //     type: 'SIGNIN_SUCCESS',
            //     payload: response.data.rider
            // })

            axios.defaults.headers.common["Authorization"] = response.data.token

            history.push("/")
    
            resetForm();
            setStatus({ sent: true });
            setSubmitting(false);
        } catch (error) {
            setStatus({ sent: false });
            setErrors({ submit: "An error occurred while processing request" });
            setSubmitting(false);
        }
    };

    return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status,
        }) => (
        <Box
            sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            }}
        >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Sign in
            </Typography>
            {isSubmitting ? (
                <Box display="flex" justifyContent="center" my={6}>
                    <CircularProgress />
                </Box>
            ) : (
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    value={values.phone_number}
                    error={Boolean(touched.phone_number && errors.phone_number)}
                    helperText={touched.phone_number && errors.phone_number}
                    fullWidth
                    type="number"
                    label="Phone Number"
                    name="phone_number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                <TextField
                    margin="normal"
                    value={values.password}
                    error={Boolean(errors.password && touched.password)}
                    helperText={touched.password && errors.password}
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    onChange={handleChange}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                Login
                </Button>
            </Box>
            )}
        </Box>
        )}
      </Formik>
    )
}

export default LoginForm
