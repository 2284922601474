import React from 'react';
import Button from '@mui/material/Button';
import CloudDone from '@mui/icons-material/CloudDone';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

// Redux
import { markOrderCompleted } from '../../redux/actions/delivery';
import { useDispatch, useSelector } from 'react-redux';

const MarkDeliveredButton = ({ orderNumber }) => {
    const dispatch = useDispatch()
    const { loadingMarkComplete } = useSelector(state => state.delivery)
    
    return (
        loadingMarkComplete ? (
            <Box display="flex" justifyContent="center">
                <CircularProgress />
            </Box>
        ) : (
            <Button variant="outlined" style={{width: '370px', marginBottom: '20px'}} 
                onClick={() => markOrderCompleted(dispatch, orderNumber)} 
                startIcon={<CloudDone />}> Mark as Completed
            </Button>
        )
    )
}

export default MarkDeliveredButton
