import * as React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import LogoutIcon from '@mui/icons-material/Logout';
import { useHistory, Link } from 'react-router-dom'

import { useDispatch } from 'react-redux';
import { logoutUser } from '../../redux/actions/auth';

const BottomNav = () => {
  const [value, setValue] = React.useState(0);

  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <Box sx={{ width: 500 }}>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction 
          component={Link}
          to="/"
          label="Dashboard"
          icon={<HomeOutlinedIcon />} 
          />
          <BottomNavigationAction 
          component={Link}
          to="/deliveries"
          label="Deliveries" 
          icon={<DirectionsBikeIcon />} 
          />
          <BottomNavigationAction label="Logout" onClick={() => logoutUser(history, dispatch)} icon={<LogoutIcon />} />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}

export default BottomNav;