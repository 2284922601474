import { 
    OPEN_ORDER_DETAILS_DRAWER,
    CLOSE_ORDER_DETAILS_DRAWER,
} from '../types/app';

const INITIAL_STATE = {
    drawerOpen: false,
}

export const appReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case OPEN_ORDER_DETAILS_DRAWER:
            return {
                ...state, 
                drawerOpen: true, 
            }
        case CLOSE_ORDER_DETAILS_DRAWER:
            return {
                ...state, 
                drawerOpen: false
            }
        default:
            return state;
    }
}