import useSwr from 'swr';
import axios from 'axios';

const deliveriesFetcher = (url, token) => axios.get(url, { headers: {Authorization: `Bearer ${token}`}}).then((res) => res.data).then(data => data.data)

const useDeliveries = (status) => {
    const rider = JSON.parse(localStorage.getItem('user'))

    const { data, mutate, error} = useSwr([`${process.env.REACT_APP_BASE_URL}delivery/rider/orders/${status}`, rider.token], deliveriesFetcher);

    const loading = !data && !error;

    return {
        loading,
        error,
        deliveries: data,
        mutate
    }
}

export default useDeliveries