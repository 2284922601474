import React from 'react';
import styled from 'styled-components';
import Skeleton from '@mui/material/Skeleton';
import useDeliveries from '../../data/use-deliveries';

const OrdersCountWrapper = styled.div`
    background-color: #fff;
    box-shadow: var(--box-shadow)
    padding: 20px;
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
`

const CompletedOrders = styled.div`
    flex: 1;
    text-align: center;
    border-right: 1px solid #dedede;
`

const CompletedOrdersCount = styled.div`
    color: #2ecc71;
    font-size: 30px;
    letter-spacing: 1px;
    margin: -10px 0px 10px;
`

const PendingOrdersCount = styled.div`
    color: #c0392b;
    font-size: 30px;
    letter-spacing: 1px;
    margin: -10px 0px 10px;
`

const PendingOrders = styled.div`
    flex: 1;
    text-align: center;
`

const OrdersCount = () => {
    const { deliveries, error } = useDeliveries("pending")

    if (error) {
        console.log(error)
    }

    if (!deliveries) return (
        <Skeleton
            animation="wave"
            height={150}
            width="100%"
            style={{ marginBottom: 6 }}
        />
    )

    return (
        <OrdersCountWrapper>
            <CompletedOrders>
                <h4>Completed Deliveries</h4>
                <CompletedOrdersCount>40</CompletedOrdersCount>
            </CompletedOrders>
            <PendingOrders>
                <h4>Pending Deliveries</h4>
                <PendingOrdersCount>{deliveries.length}</PendingOrdersCount>
            </PendingOrders>
        </OrdersCountWrapper>
    )
}

export default OrdersCount
