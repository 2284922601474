import React, { useState } from 'react';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import useDeliveries from '../../data/use-deliveries';

import { useDispatch } from 'react-redux'
import { OPEN_ORDER_DETAILS_DRAWER } from '../../redux/types/app';

import { OrdersList } from './OrdersList';
import DeliveryDetails from '../DeliveryOrderDetails/DeliveryOrderDetails';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import NoResults from '../NoResults/NoResults';

const PendingDeliveries = () => {
        const dispatch = useDispatch()

        const [orderDetails, setOrderDetails] = useState({})
        
        const { deliveries, error } = useDeliveries("pending");

        if (error) {
            return <ErrorMessage message={error}/>
        }

        if (!deliveries) return (
            <>
                <p>Pending Deliveries</p>
                <ListItem>
                    <Skeleton
                        animation="wave"
                        height={80}
                        width="100%"
                        style={{ marginBottom: 6 }}
                    />
                </ListItem>
                <Divider/>
            </>
        )

        const toggleDrawer = (anchor, open, orderNumber) => (event) => {
            if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                return;
            }

            const orderDetails = deliveries.find(order => order.order_number === orderNumber)

            if(open){
                setOrderDetails({
                    orderDetails
                })
            }

            dispatch({
                type: OPEN_ORDER_DETAILS_DRAWER
            })
        };

        return (
            <>
                <p>Pending Deliveries</p>
                {deliveries.length === 0 ? 
                    <NoResults/> 
                : (
                    <>
                        <OrdersList deliveries={deliveries} toggleDrawer={toggleDrawer}/>
                        <DeliveryDetails orderDetails={orderDetails} toggleDrawer={toggleDrawer}/>
                    </>
                )}
            </>
        );
}

export default PendingDeliveries