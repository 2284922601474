import { 
    LOADING_LOGIN, 
    SET_ERRORS, 
    CLEAR_ERRORS, 
    SET_USER, 
    LOGOUT
} from '../types/auth';

const initialState = {
    loadingLogin: false, 
    errors:{},
}

export const userReducer = (state = initialState, action) => {
    switch(action.type){
        case LOADING_LOGIN:
            return {
                ...state,
                loadingLogin: true,
            }
        case SET_ERRORS:
            return {
                ...state, 
                loadingLogin: false,
                errors: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state, 
                loadingLogin: false,
                errors:{
                    email: '',
                    password:'',
                    general:''
                }
            }
        case SET_USER:
            return {
                ...state, 
                loadingLogin: false,
                errors: {},
                ...action.payload
            }
        case LOGOUT:
            return state
        default:
            return state;

    }
}