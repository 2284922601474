import React from 'react'
import Header from '../components/AppBar/AppBar'
import { CompletedDeliveries } from '../components/Deliveries/CompletedDeliveries'
import BottomNav from '../components/BottomNav/BottomNav'
import Container from '@mui/material/Container';
import jwt_decode from 'jwt-decode';

const Deliveries = () => {
    const rider = JSON.parse(localStorage.getItem('user'))

    if(!rider){
        window.location.href = "/login"
    }

    let tokenData

    if (rider.token){
        // decode the token
        tokenData = jwt_decode(rider.token)
    }

    if(!rider.token || (Date.now() >= new Date(tokenData.exp).getTime() * 1000)){
        localStorage.clear();
            
        window.location.href = "/auth/login"
    }

    return (
        <div>
            <Header/>
            <Container maxWidth="sm">
                <CompletedDeliveries />
                <BottomNav/>
            </Container>
        </div>
    )
}

export default Deliveries
